<template>
  <div class="postponed-transaction-popup">
    <colored-popup
      class="warning"
      :close-action="closeAction">
      <template v-slot:top-content>
        <div class="postponed-transaction-popup-header">
          <div>המערכת זיהתה עסקה דחויה:</div>
          <transaction-details class="transaction-details" :transaction="transaction" />
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="explanation">
          <template v-if="transaction.isPostponedOpenBanking">
            <span>העסקה הזו בוצעה ב{{ transactionDateMonth }}, והיא</span>
            <span class="bold">תרד בחיוב האשראי {{ postponedTransactionBillingMonthText }}.</span>
          </template>
          <template v-else>
            <span>זאת עסקה דחויה, ולכן היא</span>
            <span class="bold">תרד רק בחיוב האשראי של חודש {{billingDateMonthName}}.</span>
          </template>
        </div>
        <callout
                 iconPath="icons/heart.svg"
                 class="tooltip" title="טיפ מאיתנו">
          <template v-slot:text>
            <div>אפשר לבקש מחברת האשראי לשנות את מועד החיוב של עסקאות חו”ל למועד החיוב הרגיל שלך.</div>
          </template>
        </callout>
        <riseup-button class="btn" title="תודה, הבנתי" variant="primary" size="slim" :action="handleUnderstoodClicked"/>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import dateUtils from '@/utils/dates';
import TransactionDetails
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/TransactionDetails';

export default {
  name: 'PostponedTransactionPopup',
  components: {
    TransactionDetails,
    ColoredPopup: BaseUI.ColoredPopup,
    Callout: BaseUI.Callout,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['isPresentingCurrentMonth']),
    transactionDateMonth() {
      return dateUtils.getHebrewMonth(this.transaction.transactionDate);
    },
    billingDateMonthName() {
      return dateUtils.getHebrewMonth(this.transaction.billingDate);
    },
    postponedTransactionBillingMonthText() {
      return this.isPresentingCurrentMonth ? 'הקרוב' : `של חודש ${this.billingDateMonthName}`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    handleUnderstoodClicked() {
      Segment.trackUserInteraction('PostponedTransactionPopup_UnderstoodClicked', { transactionId: this.transaction.transactionId });
      this.closeModal();
    },
    closeAction() {
      Segment.trackUserInteraction('PostponedTransactionPopup_CloseClicked', { transactionId: this.transaction.transactionId });
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.postponed-transaction-popup {
  text-align: right;
  .close-button {
    width: 100%;
  }

  .postponed-transaction-popup-header {
    color: $riseup_gray_80;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;

    .transaction-details {
      margin-top: 10px;
    }
  }

  .explanation {
    font-size: 20px;
    line-height: 28px;
    margin: 6px 0 26px 0;
  }

  .btn {
    margin-top: 22px;
  }
}

</style>
