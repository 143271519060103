<template>
  <div class="plan-ahead-transaction-label" @click.stop="openPlanDetails">{{ plan.name }}  {{ plan.unicodeIcon }}</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import PlanDetailsPage from './PlanDetailsPage';

export default {
  name: 'PlanAheadTransactionLabel',
  props: {
    planId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('planAhead', ['plans']),
    plan() {
      return this.plans.find(plan => plan._id === this.planId);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPlanDetails() {
      Segment.trackUserGot('PlanAheadTransactionLabel_OpenPlanDetails_Clicked', { planId: this.planId });
      this.openModal({
        component: PlanDetailsPage,
        props: {
          plan: this.plan,
        },
        popupAlignment: 'full-screen',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.plan-ahead-transaction-label {
  white-space: pre;
}
</style>
