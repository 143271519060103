<template>
  <div class="postponed-transaction" @click.stop="openPostponedTransactionPopup">
    <span>עסקה דחויה</span>
    <span class="underline">מה זה אומר?</span>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import PostponedTransactionPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/PostponedTransactionPopup';
import Segment from '@/Segment';

export default {
  name: 'PostponedTransactionLabel',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPostponedTransactionPopup() {
      Segment.trackUserGot('PostponedTransactionLabelClicked', {
        transactionId: this.transaction.transactionId,
        businessName: this.transaction.businessName,
      });
      this.openModal({
        component: PostponedTransactionPopup,
        props: { transaction: this.transaction },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .postponed-transaction{
    display: inline-block;
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    background: $riseup_light_blue;

    .underline {
      color: $riseup_blue;
      font-weight: bold;
      text-decoration: underline
    }
  }

</style>
