<template>
  <div class="transaction-row-base" :class="{'selected': isSelected, 'unselected': !isSelected, 'out-of-date': outOfDate, 'clickable': isEditable }">
    <div class="transaction-date">
      <div v-if="hasActual">{{ formatDate(transactionDate) }}</div>
    </div>
    <div class="actual-amount" :class="{'split-transaction': splitFromTransaction}">
      <div :class="{'bold': actualAmount > 0}">
        <decimal-number :value="actualAmount" />
        <span> ₪</span>
      </div>
      <img class="temp" v-if="isTemp" :src="require('@/assets/icons/temp_transaction_small.svg')"/>
      <div class="split" v-if="splitFromTransaction">מתוך  {{ getTransactionAmount(splitFromTransaction) }} ₪</div>
    </div>
    <div class="transaction-description" v-if="transactionDescription">
      {{ transactionDescription }}
    </div>
    <div v-if="predictedAmount" class="predicted-amount">
      <decimal-number :value="predictedAmount" />
      <span> ₪</span>
    </div>
    <div class="customer-comment-container" v-if="hasAnyComment">
      <postponed-transaction-label class="postponed-transaction-label" v-if="isPostponed && transaction" :transaction="transaction" />
      <div v-if="customerComment" class="customer-comment">{{ customerComment }}</div>
      <div v-else-if="paymentAppDetails" class="customer-comment">{{ paymentAppDetails }}</div>
      <div v-if="isBiMonthlyTransaction" class="bi-monthly-comment customer-comment">הוצאה דו־חודשית</div>
      <plan-ahead-transaction-label v-if="transactionPlan" :key="transactionPlan" class="customer-comment" :plan-id="transactionPlan"/>
    </div>
    <div class="option-btn" :class="{'disabled': !isEditable }">
      <img :src="require('@/assets/icons/vertical-three-dots.svg')"/>
      <Icon class="update-indicator padding-right-xs" v-if="hasUpdate" icon-name="circle" fill :color="indicatorColor" size="8px"/>
    </div>
    <slot name="on-transaction-label" class="on-transaction-label"></slot>
  </div>
</template>

<script>
import DecimalNumber from '@/base-components/DecimalNumber';
import BaseUI from '@riseupil/base-ui';
import PostponedTransactionLabel
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/PostponedTransactionLabel';
import TransactionDisplayUtils from '../../TransactionDisplayUtils.vue';
import PlanAheadTransactionLabel from '../../../../../plan-ahead/PlanAheadTransactionLabel';

export default {
  name: 'TransactionRowBase',
  mixins: [TransactionDisplayUtils],
  components: {
    PlanAheadTransactionLabel,
    PostponedTransactionLabel,
    DecimalNumber,
    Icon: BaseUI.Icon,
  },
  props: {
    actualAmount: {
      type: Number,
      required: false,
    },
    predictedAmount: {
      type: Number,
      required: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
    transactionDate: {
      type: String,
      required: false,
    },
    transactionDescription: {
      type: String,
      required: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    customerComment: {
      type: String,
      required: false,
    },
    paymentAppDetails: {
      type: String,
      required: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    splitFromTransaction: {
      type: Object,
    },
    source: {
      type: String,
      required: false,
    },
    hasUpdate: {
      type: Boolean,
      required: false,
    },
    isPostponed: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBiMonthlyTransaction: {
      type: Boolean,
      required: false,
      default: false,
    },
    transaction: {
      type: Object,
      required: false,
    },
    isTemp: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      indicatorColor: BaseUI.Colors.riseupDarkGreen,
    };
  },
  computed: {
    hasActual() {
      return this.actualAmount;
    },
    transactionPlan() {
      return this.transaction?.plan;
    },
    hasAnyComment() {
      return this.customerComment || this.paymentAppDetails || this.isBiMonthlyTransaction || this.isPostponed || this.transactionPlan;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '@/scss/mixins';

.transaction-row-base {
  color: $riseup_black;
  display: grid;
  @include cashflow-grid-columns;
  grid-template-rows: 25px 1fr;
  padding: 10px 17px 0 24px;
  &.clickable {
    cursor: pointer;
  }
  .bold{
    font-weight: bold;
  }

  .transaction-date {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 12px;
    padding-right: 7px;
  }

  .actual-amount {
    min-height: 18px;
    &.split-transaction {
      grid-column: 2/4;
    }
    &:not(.split-transaction) {
      grid-column: 2/3;
    }

    grid-row: 1/2;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .split {
    margin-right: 5px;
    font-size: 12px;
    line-height: 18px;
    background-color: $riseup_white;
    padding: 1px 5px;
    border-radius: 4px;
  }
   .temp {
     margin-right: 7px;
   }
  .transaction-description {
    grid-column: 2/4;
    grid-row: 2/3;
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 18px;
  }

  .predicted-amount {
    grid-column: 3/4;
    grid-row: 1/2;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .customer-comment-container {
    grid-row: 3/4;
    margin-bottom: 16px;
  }

  .customer-comment-container{
    grid-column: 2/4;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    line-height: 12px;
  }

  .customer-comment, .on-transaction-label, .is-temp, .postponed-transaction-label {
    font-size: 12px;
    border-radius: 4px;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .on-transaction-label{
    grid-column: 2/5;
    grid-row: 4/4;
    margin-bottom: 16px;
  }

  .customer-comment {
    display: inline-block;
    padding: 5px;
    background-color: $riseup_gray_5;
  }

  .bi-monthly-comment {
    line-height: 18px;
    background: white;
    padding: 0 8px;
  }

  .is-temp {
    padding: 5px;
    background-color: $riseup_light_blue;
  }

  .option-btn {
    grid-column: 4/5;
    grid-row: 1/3;
    align-self: center;
    justify-self: center;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    padding-right: $ri-spacing-xxs;

    &.disabled{
      opacity: 0.2;
    }
  }

  &.out-of-date {
    color: $riseup_gray_2;
  }
}

.unselected {
  background-color: $riseup_gray_01;
}

.selected{
  background-color: $riseup_gray_10;
}

</style>
